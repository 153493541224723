export class UtilsNumber {
  public static between(
    value: number,
    valor_1_peq: number,
    valor_2_may: number
  ): boolean {
    if (value >= valor_1_peq && value <= valor_2_may) {
      return true;
    }
    return false;
  }

  public static RoudTwoDecimals(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  public static MaxValueFromArray(arr: number[]) {
    return Math.max(...arr);
  }
}
