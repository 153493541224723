//
// Constantes de permisos disponibles
//

export class Permission {
    public static readUsuarios = 'read_usuarios';
    public static readRoles = 'read_roles';
    public static readPartes = 'read_partes';
    public static Admin = 'admin';
    public static read_ayuntamiento = 'read_ayuntamiento';
    public static read_admin = 'read_admin';
    public static read_visualizador = 'read_visualizador';
}
