
















































































































import { Component, Mixins, PropSync, Watch } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { config_gaugesDto } from "@/shared/dtos/config_gaugesDto";
import { UtilsNumber } from "@/utils/utils-number";
@Component({
  components: { DxColorBox: () => import("devextreme-vue/color-box") },
})
export default class config_dashboards_limits extends Mixins(
  base_class_pantalla
) {
  @PropSync("datasource") datasourcesync!: config_gaugesDto[];
  public respu: config_gaugesDto[] = [];
  public editedItem: config_gaugesDto = new config_gaugesDto();
  public defaultItem: config_gaugesDto = new config_gaugesDto({
    id: 0,
    color: "fafafa",
  });
  public dialog: boolean = false;
  public dialogDelete: boolean = false;
  public editedIndex = -1;
  public headers = [
    {
      text: "Limite mínimo",
      value: "limit_min",
    },
    {
      text: "Limite máximo",
      value: "limit_max",
    },
    { text: "Color", value: "color" },
    { text: "", value: "actions", sortable: false },
  ];

  created() {
    this.nombre_pantalla = "Limites cuadro de mando";
    this.toolbar_texto_editar = "Editar limites";
    this.toolbar_texto_crear = "Crear nuevo limite";
    this.es_edicion = Number.parseInt(this.$route.params.id) === -1;
  }

  public get formTitle() {
    return this.editedIndex === -1 ? "Limite dashboard" : "Editar Limite";
  }

  @Watch("dialog")
  public auto_minimo() {
    if (this.dialog && this.editedIndex === -1) {
      this.editedItem.limit_min = 0;
      this.editedItem.color = "#544848";
      if (this.datasourcesync.length === 0) {
        return;
      } else {
        this.editedItem.limit_min = UtilsNumber.MaxValueFromArray(
          this.datasourcesync.map((x) => x.limit_max)
        );
      }
    }
  }

  public editItem(item: config_gaugesDto) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  public deleteItem(item: config_gaugesDto) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public deleteItemConfirm() {
    this.datasourcesync.splice(this.editedIndex, 1);
    this.closeDelete();
  }

  public close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public save() {
    if (this.editedIndex > -1) {
      Object.assign(this.datasourcesync[this.editedIndex], this.editedItem);
    } else {
      this.editedItem.id = 0;
      try {
        this.datasourcesync.push(this.editedItem);
      } catch {
        this.datasourcesync = [];
        this.datasourcesync.push(this.editedItem);
      }
    }
    this.close();
  }
}
