import { Component, Vue } from "vue-property-decorator";
import { Permission } from "../permission";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { ssmPermissionService } from "../services/PermissionService";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export class base_class_pantalla extends Vue {
  public nombre_pantalla: string = "sin nombre";
  public toolbar_texto_editar: string = "sin nombre";
  public toolbar_texto_crear: string = "sin nombre";
  public es_edicion: boolean = false;
  public HasPermision() {
    return ssmPermissionService.hasPermission(Permission.read_admin);
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
